import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout, { MyLocationEnum } from "../components/Layout"
import ExpoRoom from "../components/ExpoRooms/ExpoRoom"
import MobileExpoRoom from "../components/ExpoRooms/MobileExpoRoom"
import styled from "styled-components"
import SEO, { MetaOG } from "../components/Seo"

interface DellProps {
  data: any
  location: Location
  mount: any
  transitionStatus: any
  exit: any
  entry: any
}
export default ({ data, location, transitionStatus }: DellProps) => {
  const [showInfo, setShowInfo] = useState(false)
  const [enableTilt, setEnableTilt] = useState(true)
  const [infoText, setInfoText] = useState("")
  const seo_og: MetaOG = {
    title: "Ítarefni",
    description: "Ítarefni heilbrigðisþing 2020",
  }
  const [showMobile, setShowMobile] = useState(false)
  useEffect(() => {
    var resp = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) 
    setShowMobile(resp)
  })
  if (!showMobile) {
    return (
      <Layout
        location={location}
        myLocation={MyLocationEnum.Itarefni}
        transitionStatus={transitionStatus}
        onInfo={() => setShowInfo(!showInfo)}
        showInfo={false}
        onClose={() => setShowInfo(false)}
      >
        <SEO
          title="Ítarefni"
          description="Ítarefni heilbrigðisþing 2020"
          og={seo_og}
        />
        <ExpoRoom
          enableTilt={enableTilt}
          roomNumber={6}
          data={data}
          showInfo={showInfo}
          transitionStatus={transitionStatus}
        />
      </Layout>
    )
  } else {
    return (
      <Layout
        location={location}
        myLocation={MyLocationEnum.Itarefni}
        transitionStatus={transitionStatus}
        onInfo={() => setShowInfo(!showInfo)}
        showInfo={false}
        onClose={() => setShowInfo(false)}
      >
         <SEO
          title="Ítarefni"
          description="Ítarefni heilbrigðisþing 2020"
          og={seo_og}
        />
        <MobileExpoRoom
          data={data.allExpo}
        />
      </Layout>
    )
  }
}

const NextRoom = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 30px;
  right: 30px;
  display: flex;
  .room-button {
    text-decoration: none;
  }
  /* left */
  .room-button.left {
    margin-right: 10px;
  }
`

export const query = graphql`
  query DELL {
    roomWall: file(relativePath: { eq: "squoosh_roomWall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    roomFloor: file(relativePath: { eq: "squoosh_roomFloor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    roomTop: file(relativePath: { eq: "Loft-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allExpo {
      edges {
        node {
          firstWindowSubText
          firstWindowLink
          firstWindowTitle          
          fifthWindowSubText
          fifthWindowLink
          fifthWindowTitle
          sixthWindowLink
          sixthWindowTitle
          fourthWindowSubText
          fourthWindowLink
          fourthWindowTitle
          fourthWindowSubText
          fourthWindowLink
          fourthWindowTitle
          secondWindowTitle
          secondWindowLink
          secondWindowSubText
          thirdWindowTitle
          thirdWindowLink
          thirdWindowSubText
          firstImageNode {
            publicURL
          }
          secondImageNode {
            publicURL
          }
          thirdImageNode {
            publicURL
          }
          fourthImageNode {
            publicURL
          }
          fifthImageNode {
            publicURL
          }
          sixthImageNode {
            publicURL
          }
        }
      }
    }
  }
`