import React, { useState } from "react"
import styled from "styled-components"
import { mediaMax } from "../../utils/breakpoints"
import MobileExpoItem from "./MobileExpoItem"

interface MobileExpoProps {
  data: any
}

const MobileExpoRoom = ({ data }: MobileExpoProps) => {
  return (
    <div>
     <Container>
        <ExpoContainer>
          <h1>Ítarefni</h1>
          <h2>Hér getur þú skoðað skýrslur tengdar heilbrigðisþingi 2020</h2>
          <MobileExpoItem
            title={data.edges[0].node.firstWindowTitle}
            subText={data.edges[0].node.firstWindowSubText}
            link={data.edges[0].node.firstWindowLink}
            image={data.edges[0].node.firstImageNode}
          />
          <MobileExpoItem
            title={data.edges[0].node.secondWindowTitle}
            subText={data.edges[0].node.secondWindowSubText}
            link={data.edges[0].node.secondWindowLink}
            image={data.edges[0].node.secondImageNode}
          />
          <MobileExpoItem
            title={data.edges[0].node.thirdWindowTitle}
            subText={data.edges[0].node.thirdWindowSubText}
            link={data.edges[0].node.thirdWindowLink}
            image={data.edges[0].node.thirdImageNode}
          />
          <MobileExpoItem
            title={data.edges[0].node.fourthWindowTitle}
            subText={data.edges[0].node.fourthWindowSubText}
            link={data.edges[0].node.fourthWindowLink}
            image={data.edges[0].node.fourthImageNode}
          />
          <MobileExpoItem
            title={data.edges[0].node.fifthWindowTitle}
            subText={data.edges[0].node.fifthWindowSubText}
            link={data.edges[0].node.fifthWindowLink}
            image={data.edges[0].node.fifthImageNode}
          />
          <MobileExpoItem
            title={data.edges[0].node.sixthWindowTitle}
            link={data.edges[0].node.sixthWindowLink}
            image={data.edges[0].node.sixthImageNode}
          />
        </ExpoContainer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;

  @media ${mediaMax.tablet} {
    flex-direction: column;
  }
`
const ExpoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  padding-top: 150px;

  h1 {
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    font-size: 36px;
    margin-bottom: 15px;
  }

  > h2 {
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    font-size: 30px;
    margin-bottom: 100px;
  }

  .videoContainer {
    height: 100%;
    margin-bottom: 30px;
  }

  @media ${mediaMax.tablet} {
    width: 100%;
    margin-left: 0;
  }
`

export default MobileExpoRoom
