import React, { useState } from "react"
import styled from "styled-components"

import { mediaMax } from "../../utils/breakpoints"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const MobileExpoRoomItem = ({ title, subText, link, image }: any) => {
  return (
    <ExpoItem>
      <h2>{title}</h2>
      <h3>{subText}</h3>

      <OutboundLink href={link}>
        <img src={image.publicURL} />
      </OutboundLink>
    </ExpoItem>
  )
}

const ExpoItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: 50px;

  h2,
  h3 {
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    text-align: center;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 1px;
  }

  a {
    height: 100%;
  }

  img {
    width: 100%;
  }
`

export default MobileExpoRoomItem
