import React, { useState } from "react"
import styled from "styled-components"

import { useRoomMovement } from "../RoomExperience/hooks/useRoomMovement"

import {
  Space,
  Scroller,
  Room,
  RoomSide,
  Wallpaper,
  RoomSideEnum,
  StoryItem,
  RoomHoverText,
} from "../RoomExperience"

import Img from "gatsby-image"
const ExpoRoom = ({
  data,
  transitionStatus,
  enableTilt,
  showInfo,
}: any) => {
  const { scrollerRef } = useRoomMovement({
    enableTilt,
    enableTopView: showInfo,
    transitionStatus,
  })

  const [activeTitle, setActiveTitle] = useState("")
  const [activeDescription, setActiveDescription] = useState("")

  const handleClick = (index: number) => {
    //setContentActive(!ContentActive);
    //setShowContent(!showContent);
  }

  const handleHover = (title: string, description: string) => {
    setActiveTitle(title)
    setActiveDescription(description)
  }

  return (
    <Space>
      <Scroller ref={scrollerRef}>
          <Room>
            {/* WALL: TOP  */}
            <RoomSide className={RoomSideEnum.Top}>
              <WallpaperTop
                fluid={data.roomFloor.childImageSharp.fluid}
                backgroundColor="#e3e3e3"
              />
            </RoomSide>

            {/* WALL: RIGHT  */}
            <RoomSide className={RoomSideEnum.Right}>
              <Wallpaper
                fluid={data.roomWall.childImageSharp.fluid}
                backgroundColor="#585858"
              />
              
              {data.allExpo.edges[0].node.thirdImageNode && (
                <StoryItem
                  xPos="20%"
                  heightPercentage="60%"
                  widthPercentage="20%"
                  handleHover={() =>
                    handleHover(
                      data.allExpo.edges[0].node.thirdWindowTitle,
                      data.allExpo.edges[0].node.thirdWindowSubText
                    )
                  }
                  href={data.allExpo.edges[0].node.thirdWindowLink}
                  className="tiltOnHover"
                >
                  <ActualImg
                    src={data.allExpo.edges[0].node.thirdImageNode.publicURL}
                  />
                </StoryItem>
              )}

              {data.allExpo.edges[0].node.fourthImageNode && (
                <StoryItem
                  xPos="43%"
                  heightPercentage="60%"
                  widthPercentage="20%"
                  handleHover={() =>
                    handleHover(
                      data.allExpo.edges[0].node.fourthWindowTitle,
                      data.allExpo.edges[0].node.fourthWindowSubText
                    )
                  }
                  href={data.allExpo.edges[0].node.fourthWindowLink}
                  className="tiltOnHover"
                >
                  <ActualImg
                    src={data.allExpo.edges[0].node.fourthImageNode.publicURL}
                  />
                </StoryItem>
              )}

              {data.allExpo.edges[0].node.fifthImageNode &&
                data.allExpo.edges[0].node.fifthWindowLink && (
                  <StoryItem
                    xPos="65%"
                    heightPercentage="60%"
                    widthPercentage="20%"
                    handleHover={() =>
                      handleHover(
                        data.allExpo.edges[0].node.fifthWindowTitle,
                        data.allExpo.edges[0].node.fifthWindowSubText
                      )
                    }
                    href={data.allExpo.edges[0].node.fifthWindowLink}
                    className="tiltOnHover"
                  >
                    <ActualImg
                      src={data.allExpo.edges[0].node.fifthImageNode.publicURL}
                    />
                  </StoryItem>
                )}
            </RoomSide>

            {/* WALL: LEFT  */}
            <RoomSide className={RoomSideEnum.Left}>
              <CustomGradient
                gradientCode="linear-gradient(-90deg, rgba(0,130,133,0) 0%, rgba(0,130,133,0.9) 100%)"
              /> 
              {/*
              {data.edges[0].node.logo != null && (
                <CompanyLogo src={data.edges[0].node.logo.publicURL} />
              )} */}
              <CustomWallText>Hér getur þú skoðað skýrslur tengdar heilbrigðisþingi 2020</CustomWallText>
              <Wallpaper
                fluid={data.roomWall.childImageSharp.fluid}
                backgroundColor="#585858"
              />
            </RoomSide>

            {/* WALL: BOTTOM  */}
            <RoomSide className={RoomSideEnum.Bottom}>
              <WallpaperFloor
                fluid={data.roomFloor.childImageSharp.fluid}
                backgroundColor="#585858"
              />
            </RoomSide>

            {/* WALL: BACK  */}
            <RoomSide className={RoomSideEnum.Back}>
              <WallpaperBack
                fluid={data.roomWall.childImageSharp.fluid}
                backgroundColor="#585858"
              />

              {data.allExpo.edges[0].node.firstImageNode && (
                <StoryItem
                  xPos="3%"
                  heightPercentage="60%"
                  widthPercentage="30%"
                  handleHover={() =>
                    handleHover(
                      data.allExpo.edges[0].node.firstWindowTitle,
                      data.allExpo.edges[0].node.firstWindowSubText
                    )
                  }
                  href={data.allExpo.edges[0].node.firstWindowLink}
                >
                  <ActualImg
                    src={data.allExpo.edges[0].node.firstImageNode.publicURL}
                  />
                </StoryItem>
              )}

              {data.allExpo.edges[0].node.secondImageNode && (
                <StoryItem
                  xPos="38%"
                  heightPercentage="60%"
                  widthPercentage="30%"
                  href={data.allExpo.edges[0].node.secondWindowLink}
                  handleHover={() =>
                    handleHover(
                      data.allExpo.edges[0].node.secondWindowTitle,
                      data.allExpo.edges[0].node.secondWindowSubText
                    )
                  }
                >
                  {data.allExpo.edges[0].node.secondImageNode != null && (
                    <ActualImg
                      src={data.allExpo.edges[0].node.secondImageNode.publicURL}
                    />
                  )}
                </StoryItem>
              )}
              
              {data.allExpo.edges[0].node.sixthImageNode && (
                <StoryItem
                  xPos="72%"
                  heightPercentage="60%"
                  widthPercentage="30%"
                  href={data.allExpo.edges[0].node.sixthWindowLink}
                  handleHover={() =>
                    handleHover(
                      data.allExpo.edges[0].node.sixthWindowTitle,
                      data.allExpo.edges[0].node.sixthWindowSubText
                    )
                  }
                >
                  {data.allExpo.edges[0].node.sixthImageNode != null && (
                    <ActualImg
                      src={data.allExpo.edges[0].node.sixthImageNode.publicURL}
                    />
                  )}
                </StoryItem>
              )}
              
            </RoomSide>
          </Room>

        <RoomHoverText title={activeTitle} description={activeDescription} />
      </Scroller>
    </Space>
  )
}

const CustomWallText = styled.p`
  font-size: 100px;
  color: white;
  pointer-events: auto;
  position: absolute;
  top: auto;
  left: 15%;
  width: 35%;
  transform: rotate3d(0,1,0,-6deg) translate3d(0,0,10vh);
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
`

interface GradientProps {
  gradientCode?: string
}
const CustomGradient = styled.div<GradientProps>`
  height: 100%;
  width: 100%;
  position: absolute;

  transform: translate3d(0px, 0px, 5px);

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ gradientCode }) => gradientCode};
  }
`

const ActualImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit:cover;
`

const WallpaperBack = styled(Wallpaper)`
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: left;
  background-position: center;
`
const WallpaperFloor = styled(Wallpaper)`
  background-repeat: no-repeat;
  filter: brightness(70%);
  background-size: 100% 100%;
`

const WallpaperTop = styled(Wallpaper)`
  background-repeat: no-repeat;
  background-size: 100% 100%;
`

export default ExpoRoom;